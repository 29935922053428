import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "app/store"
import {PoolsInterface} from "interfaces/retrieve";

interface PoolsState {
   pools: PoolsInterface[];
}

export const initialState: PoolsState = {
    pools: []
}

export const poolsSlice = createSlice({
    name: "pools",
    initialState,
    reducers: {
        setPools: (state,
                    action: PayloadAction<PoolsInterface[]>
        ) => {
            state.pools = action.payload
        }
    }
})

export const selectPoolsState = (state: RootState) => state.pools;

export const {
    setPools
} = poolsSlice.actions;

export default poolsSlice.reducer;