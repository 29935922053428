import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  AddAlertPayload,
  AlertState,
  AlertStateInterface,
} from "interfaces/alert";
import { RootState } from "app/store";

const initialState: AlertState = {
  alerts: {},
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    /**
     * Add one single alert
     * @param state
     * @param action
     */
    addAlert: (state, action: PayloadAction<AddAlertPayload>) => {
      state.alerts[action.payload.name] = {
        message: action.payload.message,
        type: action.payload.type,
        replacements: action.payload.replacements,
      };
    },
    removeAlert: (state, action: PayloadAction<string>) => {
      delete state.alerts[action.payload];
    },
    clearAlert: (state) => {
      state.alerts = {};
    },
    clearAlertExcept: (state, action: PayloadAction<string[]>) => {
      let excepts = action.payload;
      let keys = Object.keys(state.alerts);
      keys.forEach((key: string) => {
        if (excepts.indexOf(key) === -1) {
          delete state.alerts[key];
        }
      });
    },
    /**
     * Set alert / Completely replace state alert
     * @param state
     * @param action
     */
    setAlert: (state, action: PayloadAction<AlertStateInterface>) => {
      state.alerts = action.payload;
    },
    /**
     * Add multiple alerts
     * @param state
     * @param action
     */
    extendAlert: (state, action: PayloadAction<Object>) => {
      Object.assign(state.alerts, action.payload);
    },
  },
});

export const {
  addAlert,
  removeAlert,
  clearAlert,
  clearAlertExcept,
  setAlert,
  extendAlert,
} = alertSlice.actions;

export const selectErrors = (state: RootState) => state.alert.alerts;

export default alertSlice.reducer;
