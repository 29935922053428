import ReactHtmlParser from "html-react-parser";
import { useMemo } from "react";
import { TextViewProps } from "interfaces/textView";
import { useAppSelector } from "app/hooks";
import useResourceService from "app/useResourceService";
import { selectLanguage } from "slices/language";
import { selectLocalizations } from "slices/resourceService";

const TextView = ({
  module,
  textId,
  parseHtml,
  replacements,
}: TextViewProps) => {
  const texts = useAppSelector(selectLocalizations);
  const language = useAppSelector(selectLanguage);
  const { getText } = useResourceService();
  const replacementValues = useMemo(() => {
    if (replacements && replacements.defaultReplacement) {
      return replacements.replacements.map((replacement, index) => {
        return {
          ...replacement,
          value: `{${index}}`,
        };
      });
    }
    return replacements?.replacements;
  }, [replacements]);

  const replace = () => {
    let text = getText(textId, module);
    if (replacementValues) {
      replacementValues.forEach((replacement, index) => {
        const newText = replacement.textModule
          ? getText(replacement.replacement, replacement.textModule)
          : replacement.replacement;
        text = text.replace(replacement.value!, newText);
      });
    }
    return text;
  };

  const text = useMemo(() => {
    return replace();
  }, [textId, language, texts, replacementValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>{parseHtml ? ReactHtmlParser(text) : text}</>;
};

export default TextView;
