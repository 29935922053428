import * as React from "react";
import {
  DetailsModelInterface,
  TravelDetailsInterface,
} from "interfaces/details";
import TextView from "components/features/TextView/TextView";

export interface DetailsConfig {
  [key: string]: {
    model: DetailsModelInterface;
    travelDetails: TravelDetailsInterface;
    alternateFlights: {
      hasPriceComparisonTable: boolean;
    };
    yourSelection: {
      hasAsterisk: boolean;
    };
    priceInfoDisplay?: (
      availability: { [key: string]: any },
      formatFloatLocale: (input: number | string) => string,
    ) => React.ReactNode;
  };
}

const defaultModel: DetailsModelInterface = {
  basicFare: {
    amount: 0,
    currency: "EUR",
  },
  smartFare: {
    amount: 0,
    currency: "EUR",
  },
  origin: "",
  pool: "",
  numAdt: 1,
  numChd: 0,
  numInf: 0,
  minStay: 1,
  maxStay: 2,
  dayType: "",
  earliestOut: "",
  latestRet: "",
  timeRestriction: "",
  travelClass: "ECONOMY",
  isTravelingBusiness: false,
  poolDestinationsData: [],
  poolFromPrice: 0,
  imageFileName: "",
  availableStayValues: [],
  totalPrice: {
    amount: 0,
    currency: "EUR",
  },
  filterData: {
    maxStay: {
      start: 1,
      end: 11,
      titleKey: false,
    },
    minStay: {
      start: 1,
      end: 8,
      titleKey: false,
    },
    numAdt: {
      start: 1,
      end: 9,
      titleKey: false,
    },
    numChd: {
      start: 0,
      end: 8,
      titleKey: false,
    },
    numInf: {
      start: 0,
      end: 1,
      titleKey: false,
    },
    dayType: {
      values: ["FRI_SUN", "SAT_MON", "SATURDAY"],
      emptyTopLabel: "FULLFLEX",
    },
    timeRestriction: {
      values: ["NOTEARLY", "LATEFLIGHTS"],
      emptyTopLabel: "FULLFLEX",
    },
    travelClass: {
      values: ["ECONOMY", "BUSINESS"],
    },
  },
};

const defaultTravelDetails: TravelDetailsInterface = {
  hasTravelClass: true,
  departureBuffer: 0,
  returnBuffer: 2,
  maxPax: 9,
  minMaxDiff: 3,
  adtNumLabel: "numAdt_plural",
  chdNumLabel: "numChd_plural",
  infNumLabel: "numInf_plural",
  hasWeekendOptionsModal: false,
  calendarFixedHeight: true,
};

const defaultConfiguration = {
  model: {
    ...defaultModel,
    filterData: {
      ...defaultModel.filterData,
      numAdt: {
        ...defaultModel.filterData.numAdt,
        titleKey: "numAdt",
      },
      numChd: {
        ...defaultModel.filterData.numChd,
        titleKey: "numChd",
      },
      numInf: {
        ...defaultModel.filterData.numInf,
        titleKey: "numInf",
      },
    },
  },
  travelDetails: {
    ...defaultTravelDetails,
    adtNumLabel: undefined,
    chdNumLabel: undefined,
    infNumLabel: undefined,
  },
  alternateFlights: {
    hasPriceComparisonTable: true,
  },
  yourSelection: {
    hasAsterisk: false,
  },
};

const configuration: DetailsConfig = {
  ewg: {
    model: {
      ...defaultModel,
      minStay: 2,
      maxStay: 3,
      travelClass: "",
      filterData: {
        ...defaultModel.filterData,
        maxStay: {
          ...defaultModel.filterData.maxStay,
          end: 12,
        },
        minStay: {
          ...defaultModel.filterData.minStay,
          end: 9,
        },
      },
    },
    travelDetails: {
      hasTravelClass: false,
      departureBuffer: 0,
      returnBuffer: 7,
      maxPax: 9,
      minMaxDiff: 3,
      adtNumLabel: "numAdt_plural",
      chdNumLabel: "numChd_plural",
      infNumLabel: "numInf_plural",
      hasWeekendOptionsModal: true,
      calendarFixedHeight: false,
    },
    alternateFlights: {
      hasPriceComparisonTable: false,
    },
    yourSelection: {
      hasAsterisk: true,
    },
    priceInfoDisplay: (availability, formatFloatLocale) => (
      <div className="row total-container container">
        <div className="price-info nopaddingR">
          <span>
            <TextView textId={"price_info"} />
          </span>
        </div>

        <div className="nopaddingR alternative-price-container">
          <span className="alternative-price-label">
            <TextView textId={"alternative_price_label"} />
          </span>
          <span className="alternative-price-value">
            <span id="bestAlternativePrice" className="text-right">
              {formatFloatLocale(availability.bestAlternativePrice.amount)}
            </span>
            &euro;
          </span>
        </div>
        <div className="nopaddingR total-price-container">
          <span className="total-label">
            <TextView textId={"total"} />
          </span>
          <span className="total-value">
            <span id="totalPrice" className="text-right">
              {formatFloatLocale(availability.totalPrice.amount)}
            </span>
            &euro;
          </span>
        </div>
      </div>
    ),
  },
  lhs: {
    model: {
      ...defaultModel,
      filterData: {
        ...defaultModel.filterData,
        numAdt: {
          ...defaultModel.filterData.numAdt,
          titleKey: "numAdt",
        },
        numChd: {
          ...defaultModel.filterData.numChd,
          titleKey: "numChd",
        },
        numInf: {
          ...defaultModel.filterData.numInf,
          titleKey: "numInf",
        },
      },
    },
    travelDetails: {
      ...defaultTravelDetails,
      adtNumLabel: undefined,
      chdNumLabel: undefined,
      infNumLabel: undefined,
    },
    alternateFlights: {
      hasPriceComparisonTable: true,
    },
    yourSelection: {
      hasAsterisk: false,
    },
    priceInfoDisplay: (availability, formatFloatLocale) => (
      <div className="row total-container container">
        <div className="price-info nopaddingR">
          <span>
            <TextView textId={"price_info"} />
          </span>
        </div>

        <div className="nopaddingR alternative-price-container">
          <span className="alternative-price-label">
            <TextView textId={"alternative_price_label"} />
          </span>
          <span className="alternative-price-value">
            <span id="bestAlternativePrice" className="text-right">
              {formatFloatLocale(availability.bestAlternativePrice.amount)}
            </span>
            &euro;
          </span>
        </div>
        <div className="nopaddingR total-price-container">
          <span className="total-label">
            <TextView textId={"total"} />
          </span>
          <span className="total-value">
            <span id="totalPrice" className="text-right">
              {formatFloatLocale(availability.totalPrice.amount)}
            </span>
            &euro;
          </span>
        </div>
      </div>
    ),
  },
  oss: {
    model: defaultModel,
    travelDetails: defaultTravelDetails,
    alternateFlights: {
      hasPriceComparisonTable: true,
    },
    yourSelection: {
      hasAsterisk: false,
    },
    priceInfoDisplay: (availability, formatFloatLocale) => (
      <div className="row total-container container">
        <div className="taxes-price-container">
          <div className="display-on-large price-info colPriceInfo nopaddingR">
            <span>
              <TextView textId={"price_info"} />
            </span>
          </div>

          <div className="nopaddingR colPriceLabel alternative-price-container">
            <span className="alternative-price-label">
              <TextView textId={"alternative_price_label"} />
            </span>
            <span className="colPriceValue alternative-price-value">
              <span id="bestAlternativePrice" className="text-right">
                {formatFloatLocale(availability.bestAlternativePrice.amount)}
              </span>
              &euro;
            </span>
          </div>
        </div>

        <div className="nopaddingR total-price-container">
          <span className="total-label">
            <TextView textId={"total"} />
          </span>
          <span className="total-value">
            <span id="totalPrice" className="text-right">
              {formatFloatLocale(availability.totalPrice.amount)}
            </span>
            &euro;
          </span>
        </div>
        <div className="display-on-small price-info colPriceInfo nopaddingR">
          <span>
            <TextView textId={"price_info"} />
          </span>
        </div>
      </div>
    ),
  },
  default: defaultConfiguration,
};

export default configuration;
