import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ContactInterface } from "interfaces/common";
import {
  BillingInterface,
  CreditCardInterface,
  DirectDebitInterface,
  PaymentModelInterface,
} from "interfaces/payment";
import { RootState } from "app/store";
import { getConfiguration } from "app/utils";
import configuration from "components/features/Payment/configuration";

const customerConfiguration = getConfiguration(configuration);
const ccCode = customerConfiguration.paymentMethods.ccConfig.ccPaymentCode;
const ddEnabled = customerConfiguration.paymentMethods.ddConfig?.ddEnabled;

interface PaymentState {
  model: PaymentModelInterface;
  paymentTriggered: boolean;
  submitBooking: boolean;
  triggerValidate: boolean;
  selectedPaymentMethod: string;
  triggerIframeReload: boolean;
  fqtvNotValidated: boolean;
  challengeInProgress: boolean;
}

const initialState: PaymentState = {
  model: customerConfiguration.model,
  paymentTriggered: false,
  submitBooking: false,
  triggerValidate: false,
  selectedPaymentMethod: ddEnabled ? "DIRECTDEBIT" : ccCode,
  triggerIframeReload: false,
  fqtvNotValidated: false,
  challengeInProgress: false,
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPaymentModel: (
      state,
      action: PayloadAction<Partial<PaymentModelInterface>>,
    ) => {
      state.model = {
        ...state.model,
        ...action.payload,
      };
    },
    setCcModel: (
      state,
      action: PayloadAction<Partial<CreditCardInterface>>,
    ) => {
      state.model.cc = {
        ...state.model.cc,
        ...action.payload,
      };
    },
    setDdModel: (
      state,
      action: PayloadAction<Partial<DirectDebitInterface>>,
    ) => {
      state.model.dd = {
        ...state.model.dd,
        ...action.payload,
      };
    },
    setBillingModel: (
      state,
      action: PayloadAction<Partial<BillingInterface>>,
    ) => {
      state.model.billing = {
        ...state.model.billing,
        ...action.payload,
      };
    },
    setContactModel: (
      state,
      action: PayloadAction<Partial<ContactInterface>>,
    ) => {
      state.model.contact = {
        ...state.model.contact,
        ...action.payload,
      };
    },
    setPaymentTriggered: (state, action: PayloadAction<boolean>) => {
      state.paymentTriggered = action.payload;
    },
    setSubmitBooking: (state, action: PayloadAction<boolean>) => {
      state.submitBooking = action.payload;
    },
    setTriggerValidate: (state, action: PayloadAction<boolean>) => {
      state.triggerValidate = action.payload;
    },
    setPaymentMethod: (state, action: PayloadAction<string>) => {
      state.selectedPaymentMethod = action.payload;
    },
    triggerIframeReload: (state, action: PayloadAction<boolean>) => {
      state.triggerIframeReload = action.payload;
    },
    setFqtvNotValidated: (state, action: PayloadAction<boolean>) => {
      state.fqtvNotValidated = action.payload;
    },
    setChallengeInProgress: (state, action: PayloadAction<boolean>) => {
      state.challengeInProgress = action.payload;
    },
    resetPayment: (state) => {
      state.model = initialState.model;
      state.submitBooking = initialState.submitBooking;
      state.paymentTriggered = initialState.paymentTriggered;
      state.selectedPaymentMethod = initialState.selectedPaymentMethod;
      state.triggerValidate = initialState.triggerValidate;
      state.fqtvNotValidated = initialState.fqtvNotValidated;
      state.challengeInProgress = initialState.challengeInProgress;
    },
  },
});

export const {
  setPaymentModel,
  setCcModel,
  setDdModel,
  setBillingModel,
  setPaymentTriggered,
  setSubmitBooking,
  resetPayment,
  setContactModel,
  setTriggerValidate,
  setPaymentMethod,
  triggerIframeReload,
  setFqtvNotValidated,
  setChallengeInProgress,
} = paymentSlice.actions;

export const selectPaymentModel = (state: RootState) => state.payment.model;
export const selectPaymentTriggered = (state: RootState) =>
  state.payment.paymentTriggered;
export const selectSubmitBooking = (state: RootState) =>
  state.payment.submitBooking;
export const selectTriggerValidate = (state: RootState) =>
  state.payment.triggerValidate;
export const selectPaymentMethod = (state: RootState) =>
  state.payment.selectedPaymentMethod;
export const selectTriggerIframeReload = (state: RootState) =>
  state.payment.triggerIframeReload;
export const selectFqtvNotValidated = (state: RootState) =>
  state.payment.fqtvNotValidated;
export const selectChallengeInProgress = (state: RootState) =>
  state.payment.challengeInProgress;
export default paymentSlice.reducer;
