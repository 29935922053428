import moment from "moment";
import "moment/locale/cs";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/sv";
import {
  CountryResource,
  LocalizationResource,
} from "interfaces/resourceService";
import { SelectOptionInterface } from "interfaces/select";
import { TextModules } from "interfaces/textView";
import { useAppSelector } from "app/hooks";
import { getConfiguration, getCurrentPage } from "app/utils";
import { selectLanguage } from "slices/language";
import {
  selectConfiguration,
  selectLocalizations,
  selectResourcesLoaded,
} from "slices/resourceService";
import conf from "components/features/Common/configuration";

const useResourceService = () => {
  const texts = useAppSelector(selectLocalizations);
  const language = useAppSelector(selectLanguage);
  const configuration = useAppSelector(selectConfiguration);
  const isProduction = configuration.system === "production";
  const resourcesLoaded = useAppSelector(selectResourcesLoaded);
  const customerConfiguration = getConfiguration(conf);

  const getModule = (module?: TextModules) => {
    if (module) {
      return module;
    }

    switch (getCurrentPage()) {
      case "compose-trip":
        return TextModules.OPQ_DETAILS;
      case "passengers":
        return TextModules.OPQ_PAXS;
      case "summary":
        return TextModules.OPQ_PAYMENT;
      case "confirmation":
        return TextModules.OPQ_CONFIRMATION;
      case "travel-theme":
      default:
        return TextModules.OPQ_RETRIEVE;
    }
  };

  const getText = (textId: string, module?: TextModules) => {
    const selectedModule = getModule(module);
    const targetedLangTexts = texts[language];
    if (targetedLangTexts) {
      const targetedTexts =
        targetedLangTexts[selectedModule as keyof LocalizationResource];
      if (targetedTexts) {
        const text = targetedTexts[textId];
        if (text || text === "") {
          return text;
        }
      }
    }

    return !isProduction && resourcesLoaded
      ? `Missing: ${selectedModule}/${textId}`
      : "";
  };

  const getLocale = () => {
    return (
      {
        en: "en-EN",
        de: "de-DE",
        cs: "cs-CZ",
        sv: "sv-SE",
      }[language] ?? "de-DE"
    );
  };

  const getMomentLocale = () => {
    return (
      {
        en: "en-gb",
        de: "de",
        cs: "cs",
        sv: "sv",
      }[language] ?? ("de" as moment.LocaleSpecifier)
    );
  };

  const getCountryCode = (languageCode: string): string => {
    return (
      {
        en: "en",
        de: "de",
        cs: "cz",
        sv: "se",
      }[languageCode] ?? "de"
    );
  };

  const formatFloatLocale = (input: number | string) => {
    const locale = getLocale();
    const number = typeof input === "number" ? input : parseFloat(input);

    return number.toLocaleString(locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  const getCountries = () => {
    const countries = texts[language].countries;
    const dialPrefix = configuration.dialPrefix;
    const result: CountryResource[] = [];

    for (const key in countries) {
      if (countries.hasOwnProperty(key)) {
        result.push({
          code: key,
          name: getText(key, TextModules.COUNTRIES),
          dialingPrefix: dialPrefix[key],
        });
      }
    }

    return result;
  };

  const getCountryDialPrefixSelectInfo = (sort: boolean = true) => {
    const countries = getCountries();

    const result: SelectOptionInterface[] = countries.map((value) => {
      return {
        name: `${value.name} (${value.dialingPrefix})`,
        value: value.code,
      };
    });

    if (!sort) {
      return result;
    }
    return result.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  };

  const getCountryCodeSelectInfo = (sort: boolean = true) => {
    const countries = getCountries();

    const result: SelectOptionInterface[] = countries.map((value) => {
      return {
        name: value.name,
        value: value.code,
      };
    });

    if (!sort) {
      return result;
    }
    return result.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  };

  const getDialPrefix = (countryCode: string): string => {
    const countries = getCountries();
    const country = countries.find((country) => country.code === countryCode);
    if (country) {
      return country.dialingPrefix ? country.dialingPrefix : "";
    }
    return "";
  };

  const getSalutations = () => {
    return customerConfiguration.titles.map((title) => {
      return {
        value: title,
        name: getText(title, TextModules.GENERAL),
      };
    });
  };

  const getGender = () => {
    return [
      { value: "MALE", name: getText("male", TextModules.GENERAL) },
      { value: "FEMALE", name: getText("female", TextModules.GENERAL) },
    ];
  };

  const getFqtvs = (sort: boolean = true) => {
    const fqtvs = texts[language].fqtvairl;
    const result: SelectOptionInterface[] = [];

    for (const key in fqtvs) {
      result.push({
        value: key,
        name: getText(key, TextModules.FQTVAIRL),
      });
    }

    if (!sort) {
      return result;
    }
    return result.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  };

  const isLanguageAvailable = (language: string) => {
    return Object.keys(texts).includes(language);
  };

  const formatPoolPrice = (price: number | undefined) => {
    if(!price) {
      return "";
    }
    return Number.isInteger(price) ? price : formatFloatLocale(price);
  }

  return {
    getText,
    formatFloatLocale,
    getCountryDialPrefixSelectInfo,
    getCountryCodeSelectInfo,
    getDialPrefix,
    getSalutations,
    getFqtvs,
    getGender,
    getMomentLocale,
    getLocale,
    isLanguageAvailable,
    getCountryCode,
    formatPoolPrice
  };
};

export default useResourceService;
