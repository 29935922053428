import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { ConfirmationModelInterface } from "interfaces/confirmation";

interface ConfirmationState {
  model: ConfirmationModelInterface;
}

const initialState: ConfirmationState = {
  model: {
    booking: {
      bookingId: 0,
      locator: "",
      contactEmail: "",
      contactPhone: null,
      contactMobile: null,
      pnrSegments: [],
      pnrPassengers: [],
      pnrTickets: [],
      pnrFareElements: [],
      grandTotal: {
        amount: 0,
        currency: "",
      },
      destinationName: "",
      hashedLocator: "",
    },
    formOfPayment: {
      fopType: "",
      directDebit: null,
      card: {
        type: "",
        accountNumber: "",
        expiryDate: "",
        cardHolderName: "",
        cvv: null,
      },
      threeDSecure: {
        directoryResponse: "",
        authenticationResponse: "",
        cavv: "",
        eci: "",
        dsTransactionId: "",
        version: "",
        exemption: null,
      },
      amount: {
        amount: 0,
        currency: "",
      },
      paymentId: "",
      billingAddress: {
        salutation: "",
        firstName: "",
        lastName: "",
        companyName: "",
        taxNumber: null,
        addressLine: "",
        city: "",
        postalCode: "",
        countryCode: "",
        provinceFedState: null,
        phone: null,
        mobile: "",
        emailAddress: "",
      },
    },
    returnCode: "",
    messageContext: {
      clientType: "",
      ipAddress: "",
      language: "de",
      module: "",
      pointOfSale: "DE",
      session: "",
      version: 0,
      userName: ""
    }
  },
};

export const confirmationSlice = createSlice({
  name: "confirmation",
  initialState,
  reducers: {
    setConfirmationModel: (
      state,
      action: PayloadAction<Partial<ConfirmationModelInterface>>,
    ) => {
      state.model = {
        ...state.model,
        ...action.payload,
      };
    },
    resetConfirmation: (state) => {
      state.model = initialState.model;
    },
  },
});

export const { setConfirmationModel, resetConfirmation } =
  confirmationSlice.actions;

export const selectConfirmationModel = (state: RootState) =>
  state.confirmation.model;

export default confirmationSlice.reducer;
