import {
  CommonConfigInterface,
  ContactConfigurationInterface,
  FareConditionsConfigInterface,
} from "interfaces/common";
import { TextModules } from "interfaces/textView";

const defaultFareConditions: FareConditionsConfigInterface = {
  economyConditions: {
    fareConditions: [
      {
        img: "small bag.svg",
        label: "cob1_8kgLabel",
        alt: "handbag_alt",
      },
      {
        img: "mileage_accrual.svg",
        label: "mileageAccLabel",
        alt: "miles250_alt",
      },
    ],
    labels: {
      labels: [
        {
          title: "lugRegLabel",
          text: "lugRegContLufthansa",
        },
        {
          title: "rebookNotAllowLabel",
          text: "rebookNotAllowText",
          hasIcon: true,
        },
        {
          title: "refundNotPossLabel",
          text: "refundNotPossText",
          hasIcon: true,
        },
      ],
    },
  },
  businessConditions: {
    fareConditions: [
      {
        img: "priority_boarding.svg",
        label: "priorityBoardingLabel",
        alt: "priority_boarding_alt",
      },
      {
        img: "e_journals.svg",
        label: "eJournalsLabel",
        alt: "e_journals_alt",
      },
      {
        img: "lounge.svg",
        label: "loungeLabel",
        alt: "lounge_alt",
      },
      {
        img: "fast_lane.svg",
        label: "fastLaneLabel",
        alt: "fast_lane_alt",
      },
      {
        img: "seats.svg",
        label: "middleSeatLabel",
        alt: "seats_alt",
      },
      {
        img: "seat.svg",
        label: "seatReservationLabel",
        alt: "seat_alt",
      },
      {
        img: "baggage.svg",
        label: "checkedBagsLabel",
        alt: "baggage_alt",
      },
      {
        img: "handbag.svg",
        label: "handbagLabel",
        alt: "handbag_alt",
      },
      {
        img: "cutlery.svg",
        label: "mealAndBeveragesLabel",
        alt: "cutlery_alt",
      },
      {
        img: "mileage_accrual.svg",
        label: "mileageAccLabel",
        alt: "mileageAccLabel",
      },
    ],
    labels: {
      labels: [
        {
          title: "lugRegLabel",
          text: "lugRegContLufthansa",
        },
        {
          title: "rebookNotAllowLabel",
          text: "rebookNotAllowText",
          hasIcon: true,
        },
        {
          title: "refundNotPossLabel",
          text: "refundNotPossText",
          hasIcon: true,
        },
      ],
    },
  },
};

const defaultContactConfig: ContactConfigurationInterface = {
  hasPhoneType: true,
  hasEmailInfo: false,
  hasMobileInfo: false,
  hasSetHideFileKey: true,
  hasPhoneReq: true,
};

const defaultTitles = ["mr", "ms", "divers", "none"];

const defaultConfig = {
  fareConditions: defaultFareConditions,
  contact: defaultContactConfig,
  titles: defaultTitles,
};

const configuration: CommonConfigInterface = {
  ewg: {
    fareConditions: {
      ...defaultFareConditions,
      businessConditions: {
        fareConditions: [
          {
            img: "check_in.svg",
            label: "onlineCheckInLabel",
            alt: "onlineCheckInLabel",
          },
          {
            img: "small_cabin_bag.svg",
            label: "cob1_8kgLabel",
            alt: "cob1_8kgLabel",
          },
          {
            img: "large_cabin_bag.svg",
            label: "bigBaggageLabel",
            alt: "bigBaggageLabel",
          },
          {
            img: "checked_bag.svg",
            label: "checkedBaggageLabel",
            alt: "checkedBaggageLabel",
          },
          {
            img: "seat_reservation.svg",
            label: "seatReservationLabel",
            alt: "seatReservationLabel",
          },
          {
            img: "miles.svg",
            label: "mileageAccLabel",
          },
        ],
        labels: {
          labels: [
            {
              text: "loungeAccessSmart",
            },
            {
              text: "priorityCheckIn",
            },
            {
              text: "securityFastLaneSmart",
            },
            {
              text: "rebookingNotPossible",
            },
            {
              text: "noCancellationAllowed",
            },
          ],
          notes: [
            "inclusiveForHonSenFtl",
            "inclusiveForHonSenCreditCardSmart",
            "onlySelectedAirportsSmart",
          ],
        },
      },
      economyConditions: {
        fareConditions: [
          {
            img: "check_in.svg",
            label: "onlineCheckInLabel",
            alt: "onlineCheckInLabel",
          },
          {
            img: "small_cabin_bag.svg",
            label: "cob1_8kgLabel",
          },
          {
            img: "miles.svg",
            label: "mileageAccLabel",
          },
        ],
        labels: {
          labels: [
            {
              text: "priorityCheckIn",
            },
            {
              text: "priorityBoarding",
            },
            {
              text: "loungeAccessBasic",
            },
            {
              text: "securityFastLaneBasic",
            },
            {
              text: "rebookingNotPossible",
            },
            {
              text: "noCancellationAllowed",
            },
          ],
          notes: [
            "inclusiveForHonSenFtl",
            "inclusiveForHonSenBasic",
            "inclusiveForHonBasic",
            "inclusiveForHonSenCreditCardBasic",
            "onlySelectedAirportsBasic",
          ],
        },
      },
      textModule: TextModules.OPQ_SHARED,
    },
    contact: {
      hasPhoneType: false,
      hasEmailInfo: false,
      hasMobileInfo: false,
      hasSetHideFileKey: false,
      hasPhoneReq: false,
    },
    titles: ["mr", "ms"],
  },
  lhs: {
    fareConditions: {
      ...defaultFareConditions,
      economyConditions: {
        fareConditions: [
          {
            img: "handbag.svg",
            label: "cob1_8kgLabel",
            alt: "handbag_alt",
          },
          {
            img: "cutlery.svg",
            label: "snackBevLabel",
            alt: "cutlery_alt",
          },
          {
            img: "mileage_accrual.svg",
            label: "mileageAccLabel",
            alt: "miles250_alt",
          },
        ],
        labels: {
          labels: [
            {
              title: "checkedBagsLabel",
              text: "checkedBagsText",
            },
            ...defaultFareConditions.economyConditions.labels.labels,
          ],
        },
      },
    },
    contact: defaultContactConfig,
    titles: defaultTitles,
  },
  oss: {
    fareConditions: {
      ...defaultFareConditions,
      economyConditions: {
        ...defaultFareConditions.economyConditions,
        labels: {
          labels: [
            {
              title: "checkedBagsLabel",
              text: "checkedBagsText",
            },
            ...defaultFareConditions.economyConditions.labels.labels,
          ],
        },
      },
    },
    contact: {
      ...defaultContactConfig,
      hasSetHideFileKey: false,
      hasPhoneReq: false,
    },
    titles: defaultTitles,
  },
  sns: defaultConfig,
  default: defaultConfig,
};

export default configuration;
