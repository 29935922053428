export interface OriginsConfig {
    defaultOrigin: string;
    showCode: boolean;
}

interface PoolsConfig {
    poolsPerRow: number;
    hasAsterisk: boolean;
    maxDestinations: number;
}

export interface RetrieveConfig {
    [key: string]: {
        origins: OriginsConfig;
        pools: PoolsConfig;
    }
}

const defaultCOnfiguration = {
    origins: {
        defaultOrigin: "FRA",
        showCode: false
    },
    pools: {
        poolsPerRow: 4,
        hasAsterisk: false,
        maxDestinations: 21
    }
}

const configuration: RetrieveConfig = {
    lhs: defaultCOnfiguration,
    oss: {
        origins: {
            defaultOrigin: "VIE",
            showCode: false
        },
        pools: {
            poolsPerRow: 4,
            hasAsterisk: false,
            maxDestinations: 21
        }
    },
    ewg: {
        origins: {
            defaultOrigin: "DUS",
            showCode: true
        },
        pools: {
            poolsPerRow: 3,
            hasAsterisk: true,
            maxDestinations: 12
        }
    },
    default: defaultCOnfiguration
}

export default configuration;