import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "app/store";
import { URLToObject } from "app/utils";

export interface AppState {
  isLoading: boolean; // Use to control the Loader icon spining.
  isInApp: boolean;
  isInGroupApp: boolean;
  vettingStatusFail: boolean;
  previousPage: string; // Previously Visited page
  appToken: string;
  disableApisNavigation: boolean;
  URLData: any;
  isDeeplink: boolean;
  cookiesAccepted: boolean;
  storredBackData: boolean;
  fullCkiProcess: boolean; // Is this a re-retrieve process
  adcConfirmed: boolean;
}

const initialState: AppState = {
  isLoading: false,
  isInApp: false,
  isInGroupApp: false,
  vettingStatusFail: false,
  previousPage: "",
  appToken: "",
  disableApisNavigation: false,
  URLData: {},
  isDeeplink: false,
  cookiesAccepted: true,
  storredBackData: true,
  fullCkiProcess: true,
  adcConfirmed: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsInApp: (state, action: PayloadAction<boolean>) => {
      state.isInApp = action.payload;
    },
    setVettingStatusFail: (state, action: PayloadAction<boolean>) => {
      state.vettingStatusFail = action.payload;
    },
    setAppToken: (state, action: PayloadAction<string>) => {
      state.appToken = action.payload;
    },
    setApisNavigation: (state, action: PayloadAction<boolean>) => {
      state.disableApisNavigation = action.payload;
    },
    setDeepLink: (state, action: PayloadAction<boolean>) => {
      state.isDeeplink = action.payload;
    },
    setURLData: (state, action: PayloadAction<any>) => {
      state.URLData = action.payload;
    },
    parseURLtoState: (state, action: PayloadAction<string>) => {
      const urlString = action.payload;
      // parse the URL and set initial state values
      let urlParams = URLToObject(urlString);
      let isDeeplink = false;
      if (_.isEmpty(urlParams)) {
        // Nothing to parse
        return;
      }

      // Parse different types of URL to eeStructure
      let normalizedURLParams = normalizeURLData(
        urlParams,
        DEEPLINK_URL_MAPPINGS,
      );

      if (!_.isEmpty(normalizedURLParams)) {
        let keys = _.keys(normalizedURLParams);
        state.URLData = normalizedURLParams;
        // Check for deeplink condition
        // extend the check for deeplink multiple conditions
        _.each(DEEPLINK_CONDITIONS, function (deeplinkCondition) {
          if (_.includes(keys, deeplinkCondition)) {
            isDeeplink = true;
          }
        });
        state.isDeeplink = isDeeplink;
      }
    },
    setAdcConfirmed: (state, action: PayloadAction<boolean>) => {
      state.adcConfirmed = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setIsInApp,
  setVettingStatusFail,
  setAppToken,
  setApisNavigation,
  setDeepLink,
  setURLData,
  parseURLtoState,
  setAdcConfirmed,
} = appSlice.actions;

export const selectIsInApp = (state: RootState) => state.app.isInApp;
export const selectIsInGroupApp = (state: RootState) => state.app.isInGroupApp;
export const selectAppToken = (state: RootState) => state.app.appToken;
export const selectIsLoading = (state: RootState) => state.app.isLoading;

export default appSlice.reducer;

const DEEPLINK_URL_MAPPINGS = {
  locale: "langCode",
  first_name1: "firstName",
  last_name1: "lastName",
  departure_port: "departureAirport",
  departure_date: "dayOfDeparture",
  flight_number: function (urlParams: any) {
    let carrier = urlParams.carrier || "";
    let output = ["flightNumber", carrier + urlParams.flight_number];
    return output;
  },
  paxflight: "retrievalData",
  etkt: "etix",
};

const DEEPLINK_CONDITIONS = ["lastName", "retrievalData"];

/**
 * Normalize the key name in URL data using a map.
 * @param urlData
 * @param urlMappings
 * @returns
 */
const normalizeURLData = function (urlData: any, urlMappings: any) {
  if (_.isEmpty(urlMappings)) {
    return urlData;
  }

  let normalizedURL = _.reduce(
    urlData,
    function (result: any, value: string, key: string, urlData: any) {
      let mapKey = "";

      if (urlMappings[key]) {
        // Currently, only flight_number in mapping is a function
        if (_.isFunction(urlMappings[key])) {
          // Run the function with [urlData] as arguments
          mapKey = urlMappings[key](...[urlData]);
          result[mapKey[0]] = mapKey[1];
        } else {
          mapKey = urlMappings[key];
          result[mapKey] = value;
        }
      } else {
        result[key] = value;
      }

      return result;
    },
    {},
  );

  return normalizedURL;
};
