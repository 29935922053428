import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

interface LanguageState {
  language: string;
}

export const initialState: LanguageState = {
  language: "de",
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    changeLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    resetLanguage: (state) => {
      state.language = initialState.language;
    }
  },
});

export const { changeLanguage,resetLanguage } = languageSlice.actions;

export const selectLanguage = (state: RootState) =>
  state.language.language;

export default languageSlice.reducer;
