import _ from "lodash";

export default class ApiError extends Error {
  private details: undefined;
  private ref: undefined;
  constructor(error: object) {
    super(_.get(error, "message"));
    this.name = "ApiError";
    this.message = _.get(error, "message", "UNKNOWN_ERROR");
    this.stack = new Error().stack;
    this.details = _.get(error, "details");
    this.ref = _.get(error, "ref");
  }
}
