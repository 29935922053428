import { StrictMode, Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./app/store";
import { getEnv } from "./app/utils";
import reportWebVitals from "./reportWebVitals";

const App = lazy(() => import("./App"));
const LhsApp = lazy(() => import("./customizations/lhs/LhsApp"));
const OssApp = lazy(() => import("./customizations/oss/OssApp"));
const EwgApp = lazy(() => import("./customizations/ewg/EwgApp"));
const SnsApp = lazy(() => import("./customizations/sns/SnsApp"));
const AldApp = lazy(() => import("./customizations/ald/AldApp"));

const getApp = () => {
  switch (getEnv("CUSTOMER_SOLUTION")) {
    case "LHS":
      return <LhsApp />;
    case "OSS":
      return <OssApp />;
    case "EWG":
      return <EwgApp />;
    case "SNS":
      return <SnsApp />
    case "ALD":
      return <AldApp />;
    default:
      // Demo
      return <App />;
  }
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Suspense>{getApp()}</Suspense>
      </PersistGate>
    </Provider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
