import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  extendOrderedArray,
  getConfiguration,
  overrideArrayElements,
} from "app/utils";

export interface PageControlInterface {
  url: string;
  sequence: number;
  enabled: boolean;
  id: string;
  additionalUrls?: string[];
  isActive: boolean;
  isPrevious: boolean;
  isCommon: boolean;
  show: boolean;
  showBack: boolean;
  showForward: boolean;
  forwardUrl?: string;
  backUrl?: string;
  skipLogging: boolean;
}

interface PageControlState {
  pages: PageControlInterface[];
}

interface PageControlConfiguration {
  [key: string]: PageControlState;
}

const defaultPages: PageControlState = {
  pages: [
    {
      url: "travel-theme",
      id: "path_retrieve",
      sequence: 0,
      enabled: true,
      isActive: true,
      isPrevious: false,
      isCommon: true,
      show: true,
      showBack: false,
      showForward: true,
      skipLogging: false,
    },
    {
      url: "compose-trip",
      enabled: false,
      id: "path_details",
      isActive: false,
      isPrevious: false,
      isCommon: true,
      show: true,
      sequence: 1,
      showBack: true,
      showForward: true,
      skipLogging: false,
    },
    {
      url: "passengers",
      sequence: 2,
      enabled: false,
      id: "path_paxs",
      isActive: false,
      isPrevious: false,
      isCommon: true,
      show: true,
      showBack: true,
      showForward: true,
      skipLogging: true,
    },
    {
      url: "summary",
      sequence: 3,
      enabled: false,
      id: "path_payment",
      isActive: false,
      isPrevious: false,
      isCommon: true,
      show: true,
      showBack: true,
      showForward: true,
      skipLogging: false,
    },
    {
      url: "confirmation",
      sequence: 4,
      enabled: false,
      id: "path_confirmation",
      isActive: false,
      isPrevious: false,
      isCommon: true,
      show: true,
      showBack: true,
      showForward: true,
      backUrl: "travel-theme",
      skipLogging: true,
    },
  ],
};

let ewgPages = extendOrderedArray(
  defaultPages.pages,
  {
    url: "fare",
    sequence: 2,
    enabled: false,
    id: "path_fare",
    isActive: false,
    isPrevious: false,
    isCommon: true,
    show: true,
    showBack: true,
    showForward: true,
    skipLogging: true,
  },
  "sequence",
);

ewgPages = overrideArrayElements(ewgPages, [
  { elements: { skipLogging: false }, index: 3 },
]);

const configuration: PageControlConfiguration = {
  oss: defaultPages,
  lhs: defaultPages,
  sns: defaultPages,
  ewg: {
    pages: ewgPages,
  },
  default: defaultPages,
};

const initialState = getConfiguration(configuration);

export const pageControlSlice = createSlice({
  name: "pageControl",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setNewPage: (state, action: PayloadAction<string>) => {
      const selectedPage = state.pages.find(
        (page) => `/${page.url}` === action.payload,
      );

      if (!selectedPage) {
        return;
      }

      state.pages.forEach((item, i) => {
        if (selectedPage.url === "confirmation") {
          state.pages[i].enabled = selectedPage.sequence <= item.sequence;
        } else {
          state.pages[i].enabled = selectedPage.sequence >= item.sequence;
        }
      });
    },
    setPage: (state, action: PayloadAction<PageControlInterface>) => {
      state.pages[action.payload.sequence] = action.payload;
    },
    setPages: (state, action: PayloadAction<PageControlInterface[]>) => {
      state.pages = action.payload;
    },
    resetPages: (state) => {
      state.pages = initialState.pages;
    },
  },
});

export const { setNewPage, setPages, setPage, resetPages } =
  pageControlSlice.actions;

export const selectPages = (state: RootState) => state.pageControl.pages;

export default pageControlSlice.reducer;
